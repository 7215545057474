<template>
  <div>
    <van-search v-model="searchValue" placeholder="公司名称/经销商/电话" @search="onSearch" @cancel="onCancel" />
    <van-list v-model="listLoading" :finished="listFinished" finished-text="没有更多了" @load="getList">
      <div v-for="(item,index) in listData" :key="index" @click="handleClickItem(item)" class="card_div">
        <van-cell-group>
          <template #title>
            {{item.signNumber+"("+item.productType+")"}}
            <van-tag type="danger" v-if="item.status==2">作废</van-tag>
            <van-tag type="primary" v-if="item.status==1">结单</van-tag>
          </template>
          <van-cell title="创建时间" :value="item.createTime"></van-cell>
          <van-cell title="公司名称" :value="item.companyName"></van-cell>
          <van-cell title="经销商" :value="item.agentName"></van-cell>
          <van-cell title="经销商电话" :value="item.agentPhone"></van-cell>
        </van-cell-group>
      </div>
    </van-list>
  </div>
</template>

<script>
import Vue from "vue";
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);
export default {
  data() {
    return {
      searchValue: "",
      pageSize: 10,
      currentPage: 1,
      listLoading: false,
      listFinished: false,
      listData: [],
    };
  },
  methods: {
    onSearch() {
      let that = this;
      that.listLoading = that.listFinished = false;
      that.listData = [];
      that.currentPage = 1;
      that.listLoading = true;
      that.getList();
    },
    handleClickItem(item) {
      location.href =
        "Wap_" + item.productType + "_Produce?signNumber=" + item.signNumber;
      //   let that = this;

      //   that.$router.push({
      //     path: "Wap_" + item.productType + "_Produce",
      //     query: { signNumber: item.signNumber },
      //   });
      //   if (item.productType == "ZHCS") {
      //     that.$router.push({
      //       path: "Wap_ZHCS_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   } else if (item.productType == "SZR7") {
      //     that.$router.push({
      //       path: "Wap_SZR7_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   } else if (item.productType == "WDD008") {
      //     that.$router.push({
      //       path: "Wap_WDD008_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   } else if (item.productType == "PZD001") {
      //     that.$router.push({
      //       path: "Wap_PZD001_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   } else if (item.productType == "JLCT82") {
      //     that.$router.push({
      //       path: "Wap_JLCT82_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   } else if (item.productType == "YSY001") {
      //     that.$router.push({
      //       path: "Wap_YSY001_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   } else if (item.productType == "HWSY01") {
      //     that.$router.push({
      //       path: "Wap_HWSY01_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   } else if (item.productType == "ZRD001") {
      //     that.$router.push({
      //       path: "Wap_ZRD001_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   } else if (item.productType == "RHD001") {
      //     that.$router.push({
      //       path: "Wap_RHD001_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   } else if (item.productType == "RHD008") {
      //     that.$router.push({
      //       path: "Wap_RHD008_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   } else if (item.productType == "DHY001") {
      //     that.$router.push({
      //       path: "Wap_DHY001_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   } else if (item.productType == "DLND01") {
      //     that.$router.push({
      //       path: "Wap_DLND01_Produce",
      //       query: { signNumber: item.signNumber },
      //     });
      //   }
    },
    onCancel() {},
    getList() {
      let that = this;
      that.axios
        .post("ProductQRCode/GetList", {
          onlyText: that.searchValue,
          pageSize: that.pageSize,
          currentPage: that.currentPage,
        })
        .then(function (response) {
          response.data.data.data.forEach((element) => {
            that.listData.push(element);
          });
          that.listLoading = false;
          if (that.listData.length >= response.data.data.total) {
            that.listFinished = true;
          } else {
            that.currentPage++;
          }
        });
    },
  },
  mounted() {
    document.title = "生产码查询";
  },
};
</script>

<style scoped>
.card_div {
  border-radius: 5px;
  border: 1px solid blue;
  margin: 10px;
  padding: 5px;
}
</style>